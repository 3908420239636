import PropTypes from 'prop-types';
// ----------------------------------------------------------------------
import { Box, Card, CircularProgress, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PreviewSwitcher from './PreviewSwitcher';
import TemplateComponentIndex from '../invoiceTemp/TemplateComponentIndex';
import useAuth, { FBAppName } from '../../../hooks/useAuth';
import useLink from '../../../hooks/useLink';

TemplatePreview.propTypes = {
  templateID: PropTypes.string,
  invoice: PropTypes.object,
  templateRef: PropTypes.object,
};

export default function TemplatePreview({ templateID, invoice, templateRef }) {
  const [isPreview, setIsPreview] = useState(true);
  const [invoiceObj, setInvoiceObj] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const { getGeneratedLinkDoc } = useLink();

  useEffect(() => {
    getInvoiceWithAddtionalInfo().then((data) => {
      setInvoiceObj(data);
      setIsLoading(false);
    });
  });

  const getInvoiceWithAddtionalInfo = async () => {
    const linkDoc = await getGeneratedLinkDoc(user.id, invoice.id, templateID, FBAppName.invoice_in_style);
    if (linkDoc && linkDoc.exists()) {
      const additionalInfo = linkDoc.data().additional_info;
      invoice.additionalInfo = { ...invoice.additionalInfo, ...additionalInfo };
    }
    console.log('getInvoiceWithAddtionalInfo', invoice);
    return invoice;
  };

  const renderSelectedTemplate = () => {
    const SelectedTemplate = TemplateComponentIndex[templateID];
    return SelectedTemplate ? (
      <SelectedTemplate userInvoice={invoiceObj} templateRef={templateRef} isPreview={isPreview} editable />
    ) : null;
  };

  const handlePreviewSwitch = (e, value) => {
    setIsPreview(value);
  };

  return (
    <div>
      <PreviewSwitcher OnSwitch={handlePreviewSwitch} isPreview={isPreview} />
      {isLoading || !invoiceObj ? <CircularProgress sx={{ marginY: 'auto' }} /> : renderSelectedTemplate()}
    </div>
  );
}
