/* eslint-disable no-bitwise */
import { useCallback, useEffect } from 'react';
import {
  collection,
  query as dbQuery,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  limit,
  db,
  setDoc,
  updateDoc,
  increment,
} from 'firebase/firestore';
import useFirebase from './useFirebase';
import useSync from './useSync';
import { envVariable } from '../utils/constant';

const useLink = () => {
  const { firestoreDB } = useFirebase();
  const { getFBInvoiceSharableLink } = useSync();
  const hashSeed = 8808;

  const hashStr = (str, seed = hashSeed) => {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i += 1) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
  };

  // get / generate invoice sharable Link, link = https://onetracking.gelatech.com/link/ + LinkID
  //  linkid = hashStr(userID, invoiceID, tempID, appName)
  const generateInvoiceSharableLink = useCallback(async (userID, invoiceObj, tempID, appName) => {
    const linkID = hashStr(String(userID) + String(invoiceObj.id) + String(tempID) + appName);
    console.log('linkID:', linkID);
    try {
      const docRef = doc(firestoreDB, 'link', linkID);
      const linkDoc = await getDoc(docRef);
      let fbSharableLink = null;
      if (!linkDoc.exists()) {
        console.log('this linkID not in db');
        // only call getFBInvoiceSharableLink when there is no link generated before
        // this mean we will change FB invoice status to sent if it's not
        fbSharableLink = await getFBInvoiceSharableLink(invoiceObj);
      }
      setDoc(doc(firestoreDB, 'link', linkID), {
        app_name: appName,
        template_id: tempID,
        invoice_id: invoiceObj.id,
        user_id: userID,
        fb_sharable_link: fbSharableLink,
        additional_info: invoiceObj.additionalInfo || null,
      });
      return envVariable.onetracking_link_url_prefix + linkID;
    } catch (e) {
      console.error('Error get or add document to link collection: ', e);
      return null;
    }
  }, []);

  const getLinkDoc = useCallback(async (linkID) => {
    const docRef = doc(firestoreDB, 'link', linkID);
    try {
      return await getDoc(docRef);
    } catch (e) {
      console.error('Error fetch link document: ', e);
      return null;
    }
  }, []);

  const getGeneratedLinkDoc = useCallback(async (userID, invoiceID, tempID, appName) => {
    const linkID = hashStr(String(userID) + String(invoiceID) + String(tempID) + appName);
    const linkDoc = await getLinkDoc(linkID);
    return linkDoc;
  }, []);

  return {
    generateInvoiceSharableLink,
    getLinkDoc,
    getGeneratedLinkDoc,
  };
};

export default useLink;
