import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Base64 } from 'js-base64';
import moment from 'moment';
import {
  collection,
  query as dbQuery,
  where,
  getDocs,
  doc,
  addDoc,
  limit,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import rateLimit from 'axios-rate-limit';
import { envVariable } from '../utils/constant';
import { delay, allSynchronously } from '../utils/promiseUtil';
import useAuth from './useAuth';

const togglProjectNameMap = new Map();
const togglClientNameMap = new Map();

export const statusCopyMap = {
  all: 'All Status',
  ready_to_sync: 'Ready to Sync',
  syncing: 'Syncing',
  synced: 'Synced',
  error: 'Error',
};

export const autoSyncStatus = {
  draft: 'draft',
  active: 'active',
};

const useSync = () => {
  const [timeTrackingList, setTimeTrackingList] = useState([]);
  const {
    updateSyncedTimeTracking,
    updateSyncedShopifyOrder,
    getUser,
    fetchFBTokens,
    getIdentity,
    getTogglSyncToProjectID,
    getTogglSyncToClientID,
    getShopifySyncToClientID,
    getFBBusinessID,
    getFBAccountBusinessID,
    isShopifyAuth,
  } = useAuth();

  const limitedHttp = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 300 });

  const fetchShopifyOrders = useCallback(async () => {
    const user = await getUser();
    if (!isShopifyAuth) return null;
    const syncedShopifyOrders = await getSyncedShopifyOrders();

    try {
      const ordersResponse = await axios.post(`${envVariable.onetracking_backend_url}/shopifyorders`, {
        userID: user.id,
      });

      const shopifyOrdersList = ordersResponse.data?.orders;
      console.log('shopifyOrdersList', shopifyOrdersList);
      const convertedOrderList = shopifyOrdersList.map((shopifyOrder) => ({
        orderNumber: shopifyOrder.order_number,
        customer: `${shopifyOrder.customer?.first_name} ${shopifyOrder.customer?.last_name}`,
        createDate: shopifyOrder.created_at,
        currency: shopifyOrder.presentment_currency,
        totalAmount: shopifyOrder.total_price,
        totalTax: shopifyOrder.total_tax,
        paymentType: shopifyOrder.processing_method,
        paymentStatus: shopifyOrder.financial_status,
        taxLines: shopifyOrder.tax_lines,
        note: shopifyOrder.note,
        id: shopifyOrder.id,
        status:
          syncedShopifyOrders && syncedShopifyOrders[shopifyOrder.id]
            ? statusCopyMap.synced
            : statusCopyMap.ready_to_sync,
      }));
      return convertedOrderList;
    } catch (err) {
      console.log('shopify order sync error');
      console.error(err);
    }
  });

  const fetchTimeTracking = useCallback(async (togglAPIToken) => {
    if (togglAPIToken === null) return;
    setTimeTrackingList([]);
    const syncedTimeTrackList = await getSyncedTimeTracking();
    // const userObj = await getUser();
    try {
      const oldestSinceDate = moment().subtract(3, 'months').add(2, 'days');
      console.log(oldestSinceDate.format('YYYY-MM-DD'));
      const timeTrackingResponse = await axios.get(
        `https://api.track.toggl.com/api/v9/me/time_entries?since=${oldestSinceDate.unix()}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${Base64.encode(`${togglAPIToken}:api_token`)}`,
          },
        }
      );

      let togglTimeTrackingList = timeTrackingResponse.data;
      console.log(togglTimeTrackingList);
      // remove the ones that's running in progress, which have negative duration value
      togglTimeTrackingList = togglTimeTrackingList.filter((timetracking) => timetracking.duration > 0);

      let convertedTimeTrackingList = togglTimeTrackingList?.map((timetracking) => ({
        id: timetracking.id,
        startedAt: timetracking.start,
        // project: projectNameMap.get(timetracking.project_id),
        project: 'Loading...',
        client: 'Loading...',
        description: timetracking.description,
        duration: timetracking.duration,
        billable: timetracking.billable,
        isAutoSync: syncedTimeTrackList && syncedTimeTrackList[timetracking.id]?.isAutoSync,
        status:
          syncedTimeTrackList && syncedTimeTrackList[timetracking.id]
            ? statusCopyMap.synced
            : statusCopyMap.ready_to_sync,
      }));

      setTimeTrackingList(convertedTimeTrackingList);

      const togglClients = await fetchTogglClients(togglAPIToken);
      togglClients.forEach((client, index) => {
        togglClientNameMap.set(client.id, client.name);
      });
      const togglProjects = await fetchTogglProjects(togglAPIToken);
      togglProjects.forEach((project, index) => {
        togglProjectNameMap.set(project.id, {
          projectName: project.name,
          clientName: togglClientNameMap.get(project.client_id),
        });
      });
      console.log('togglProjectNameMap', togglProjectNameMap);
      console.log('togglTimeTrackingList', togglTimeTrackingList);

      convertedTimeTrackingList = togglTimeTrackingList?.map((timetracking) => ({
        id: timetracking.id,
        startedAt: timetracking.start,
        project: togglProjectNameMap.get(timetracking.project_id)?.projectName,
        client: togglProjectNameMap.get(timetracking.project_id)?.clientName,
        description: timetracking.description,
        duration: timetracking.duration,
        billable: timetracking.billable,
        isAutoSync: syncedTimeTrackList && syncedTimeTrackList[timetracking.id]?.isAutoSync,
        status:
          syncedTimeTrackList && syncedTimeTrackList[timetracking.id]
            ? statusCopyMap.synced
            : statusCopyMap.ready_to_sync,
      }));

      setTimeTrackingList(convertedTimeTrackingList);
    } catch (err) {
      console.log('timetracking sync error');
      console.error(err);
    }
  }, []);

  const fetchInvoiceByUserID = useCallback(async (invoiceID, userID) => {
    try {
      const fbInvoiceResp = await axios.post(`${envVariable.onetracking_backend_url}/fetchFBInvoiceByIDs`, {
        userID,
        invoiceID,
      });
      const fbInvoice = fbInvoiceResp.data.invoice;
      const convertedInvoice = convertFBInvoiceObj(fbInvoice);
      console.log('convertedInvoice', convertedInvoice);
      return convertedInvoice;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const MarkFBInvoiceAsSent = useCallback(async (invoiceID) => {
    try {
      const { accessToken } = await fetchFBTokens();
      const accountBusinessID = await getFBAccountBusinessID();
      const markAsSentResp = await axios.put(
        `https://api.freshbooks.com/accounting/account/${accountBusinessID}/invoices/invoices/${invoiceID}`,
        {
          invoice: {
            action_mark_as_sent: true,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log('success mark invoice as sent', markAsSentResp.data.response.result.invoice.id);
      return markAsSentResp.data.response.result.invoice;
    } catch (error) {
      console.log(error);
    }
  });

  const getFBInvoiceSharableLink = useCallback(async (invoiceObj) => {
    let fbShortUrl = null;
    let { customerID } = invoiceObj;
    const invoiceID = invoiceObj.id;
    console.log('getFBInvoiceSharableLink', invoiceID);
    if (invoiceObj.fbStatus === 'draft' || invoiceObj.fbStatus === 'created') {
      const newInvoiceObj = convertFBInvoiceObj(await MarkFBInvoiceAsSent(invoiceID));
      customerID = newInvoiceObj.customerID;
    }
    try {
      const { accessToken } = await fetchFBTokens();
      const fbIdentity = await getIdentity();
      const accountid = await getFBAccountBusinessID();
      const systemID = _.find(fbIdentity?.roles, (role) => role?.accountid === accountid)?.systemid;
      const shortLinkResp = await axios.post(
        'https://api.freshbooks.com/links/short-url',
        {
          entity_type: 'invoice',
          entity_id: invoiceID,
          system_id: systemID,
          user_id: customerID,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log('shortLinkResp', shortLinkResp);
      fbShortUrl = `https://my.freshbooks.com/#/link/${shortLinkResp.data.short_url_hash}`;
    } catch (error) {
      console.log(error);
    }
    return fbShortUrl;
  });

  const convertFBInvoiceObj = (invoice) => ({
    id: invoice.id,
    customerID: invoice.customerid,
    invoiceNumber: invoice.invoice_number,
    clientName: invoice.organization,
    description: invoice.description,
    issueDate: invoice.create_date,
    dueDate: invoice.due_date,
    totalAmount: invoice.amount.amount,
    discountInfo:
      invoice.discount_value === '0'
        ? null
        : {
            discountPercentage: invoice.discount_value,
            discountTotal: invoice.discount_total.amount,
            discountDescription: invoice.discount_description,
          },
    fbStatus: invoice.v3_status,
    fbPaymentStatus: invoice.payment_status,
    currency: invoice.currency_code,
    // for invoice template display
    lineItems: invoice.lines,
    ownerInfo: invoice.system,
    ownerContact: invoice.owner,
    clientInfo: {
      street: invoice.street,
      street2: invoice.street2,
      orgName: invoice.organization,
      province: invoice.province,
      country: invoice.country,
      code: invoice.code,
    },
    clientContacts: invoice.contacts,
    terms: invoice.terms,
    notes: invoice.notes,
    presentation: {
      logo: `https://api.freshbooks.com${invoice.presentation?.image_logo_src}`,
      dateFormat: invoice.presentation?.date_format,
    },
    paymentInfo: {
      paid: invoice.paid,
      outstanding: invoice.outstanding,
    },
    poNumber: invoice.po_number,
  });

  const fetchInvoices = useCallback(async () => {
    try {
      const { accessToken } = await fetchFBTokens();
      const accountBusinessID = await getFBAccountBusinessID();
      const invoicesResponse = await axios.get(
        `https://api.freshbooks.com/accounting/account/${accountBusinessID}/invoices/invoices?include[]=contacts&include[]=lines&include[]=owner&include[]=allowed_gateways&include[]=late_fee&include[]=presentation&include[]=late_reminders&include[]=system&per_page=100`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log('invoicesResponse.data.response.result.invoices', invoicesResponse.data.response.result.invoices);
      const freshbooksInvoices = invoicesResponse.data.response.result.invoices;
      // freshbooksProjects = _.filter(freshbooksProjects, (project) => !project.complete);
      const convertedInvoiceList = freshbooksInvoices.map((invoice) => convertFBInvoiceObj(invoice));
      console.log('convertedInvoiceList', convertedInvoiceList);
      return convertedInvoiceList;
    } catch (e) {
      console.log(e);
    }
  });

  const setupTogglWebhook = useCallback(async (workspaceID) => {
    const user = await getUser();

    try {
      const webhookResponse = await axios.post(`${envVariable.onetracking_backend_url}/toggl/setupwebhook`, {
        user_id: user.id,
        workspace_id: workspaceID,
      });
      console.log('setupTogglWebhook', webhookResponse.data?.subscription_id);
      return webhookResponse.data?.subscription_id;
    } catch (e) {
      console.log(e);
    }
  });

  const joinTogglBeta = useCallback(async () => {
    const user = await getUser();

    try {
      const webhookResponse = await axios.post(`${envVariable.onetracking_backend_url}/plan/add`, {
        user_id: user.id,
        app_name: 'Toggl',
        duration: 3,
        plan_name: 'Plus Plan',
      });
      console.log('joinTogglBeta', webhookResponse.data);
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  });

  const fetchTogglProjects = useCallback(async (togglAPIToken) => {
    try {
      if (togglAPIToken === null) return null;
      const togglProjectsResponse = await limitedHttp.get(
        `https://api.track.toggl.com/api/v9/me/projects?include_archived=false`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${Base64.encode(`${togglAPIToken}:api_token`)}`,
          },
        }
      );
      console.log('togglProjectsResponse', togglProjectsResponse.data);
      return togglProjectsResponse.data;
    } catch (e) {
      console.log(e);
    }
  });

  const fetchTogglClients = useCallback(async (togglAPIToken) => {
    try {
      if (togglAPIToken === null) return null;
      const togglClientsResponse = await limitedHttp.get(`https://api.track.toggl.com/api/v9/me/clients`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${Base64.encode(`${togglAPIToken}:api_token`)}`,
        },
      });
      console.log('togglClientsResponse', togglClientsResponse.data);
      return togglClientsResponse.data;
    } catch (e) {
      console.log(e);
    }
  });

  const deleteSelectedRecords = useCallback(async (selectedTimeTrackingList) => {
    const syncedTimeTrackMap = await getSyncedTimeTracking();
    console.log(syncedTimeTrackMap);
    selectedTimeTrackingList.forEach((record) => {
      // syncedTimeTrackMap.delete(record.id);
      delete syncedTimeTrackMap[record.id];
    });
    console.log(syncedTimeTrackMap);
    await updateSyncedTimeTracking(syncedTimeTrackMap);
  });

  const syncSelectedTimeTrackingRecordsToFB = useCallback(async (selectedTimeTrackingList, serviceID) => {
    let syncedTimeTrackMap = await getSyncedTimeTracking();
    if (!syncedTimeTrackMap) syncedTimeTrackMap = {};
    const { accessToken } = await fetchFBTokens();
    const identity = await getIdentity();
    const businessID = await getFBBusinessID();
    const synctoProjectID = await getTogglSyncToProjectID();
    const synctoClientID = await getTogglSyncToClientID();
    // const updatedTTList = _.cloneDeep(selectedTimeTrackingList || timeTrackingList);
    await Promise.all(
      selectedTimeTrackingList.map(async (tt, index) => {
        console.log(tt);
        if (tt.status === statusCopyMap.ready_to_sync) {
          if (accessToken === null) return;
          try {
            tt.status = statusCopyMap.syncing;
            const timeTrackingResponse = await axios.post(
              `https://api.freshbooks.com/timetracking/business/${businessID}/time_entries`,
              {
                time_entry: {
                  is_logged: true,
                  duration: tt.duration,
                  note: tt.description,
                  started_at: tt.startedAt,
                  project_id: synctoProjectID,
                  client_id: synctoClientID,
                  internal: !synctoClientID,
                  service_id: serviceID,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            console.log(timeTrackingResponse);
            // updatedTTList[index].status = 'synced';
            tt.status = statusCopyMap.synced;
            await delay(250 * index); // api call to FB
            syncedTimeTrackMap[tt.id] = { syncStatus: statusCopyMap.synced, syncedDate: moment().unix() };
          } catch (e) {
            console.log(e);
            tt.status = statusCopyMap.error;
          }
        }
      })
    );
    console.log('synced');
    console.log(syncedTimeTrackMap);
    updateSyncedTimeTracking(syncedTimeTrackMap);
  });

  const syncSelectedShopifyOrdersToFB = useCallback(async (selectedShopifyOrderList) => {
    let syncedShopifyOrderMap = await getSyncedShopifyOrders();
    if (!syncedShopifyOrderMap) syncedShopifyOrderMap = {};
    const { accessToken } = await fetchFBTokens();
    const identity = await getIdentity();
    const accountBusinessID = await getFBAccountBusinessID();
    const synctoClientID = await getShopifySyncToClientID();
    console.log('synctoClientID', synctoClientID);
    // const updatedTTList = _.cloneDeep(selectedTimeTrackingList || timeTrackingList);
    await Promise.all(
      selectedShopifyOrderList.map(async (shopifyOrder, index) => {
        console.log('Sycing shopify order as other income:', shopifyOrder);
        if (shopifyOrder.status === statusCopyMap.ready_to_sync) {
          if (accessToken === null) return;
          try {
            shopifyOrder.status = statusCopyMap.syncing;
            const otherIncomeRequestData = {
              other_income: {
                amount: {
                  amount: shopifyOrder.totalAmount,
                  code: shopifyOrder.currency,
                },
                category_name: 'online_sales',
                date: shopifyOrder.createDate,
                note: shopifyOrder.note,
                payment_type: 'other',
                source: 'Shopify',
                userid: synctoClientID,
              },
            };
            if (shopifyOrder.taxLines && shopifyOrder.taxLines.length > 0) {
              const taxLines = shopifyOrder.taxLines.map((taxline) => ({
                amount: taxline.price,
                name: taxline.title,
              }));
              otherIncomeRequestData.other_income.taxes = taxLines;
            }

            const OtherIncomeResponse = await axios.post(
              `https://api.freshbooks.com/accounting/account/${accountBusinessID}/other_incomes/other_incomes`,
              otherIncomeRequestData,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            console.log('Sycing shopify order as other income response:', OtherIncomeResponse);
            shopifyOrder.status = statusCopyMap.synced;
            await delay(250 * index); // api call to FB
            syncedShopifyOrderMap[shopifyOrder.id] = { syncStatus: statusCopyMap.synced, syncedDate: moment().unix() };
          } catch (e) {
            console.log(e);
            shopifyOrder.status = statusCopyMap.error;
          }
        }
      })
    );
    console.log('synced success');
    console.log('syncedShopifyOrderMap', syncedShopifyOrderMap);
    updateSyncedShopifyOrder(syncedShopifyOrderMap);
  });

  const getSyncedShopifyOrders = useCallback(async () => {
    const user = await getUser();
    console.log(user);
    console.log(user?.data.shopify_synced_orders);
    return user?.data.shopify_synced_orders || {};
  }, [getUser]);

  const getSyncedTimeTracking = useCallback(async () => {
    const user = await getUser();
    console.log(user);
    console.log(user?.data.toggl_synced_tt);
    return user?.data.toggl_synced_tt || {};
  }, [getUser]);

  const getInvoiceActivities = useCallback(async () => {
    const user = await getUser();
    console.log(user);
    console.log(user?.data.invoice_in_style_activies);
    return user?.data.invoice_in_style_activies || {};
  }, [getUser]);

  const fetchFBProjects = useCallback(async (businessID) => {
    try {
      const { accessToken } = await fetchFBTokens();
      const identity = await getIdentity();
      businessID = businessID || (await getFBBusinessID());
      const timeTrackingResponse = await axios.get(
        `https://api.freshbooks.com/timetracking/business/${businessID}/projects?per_page=2000`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(timeTrackingResponse);
      let freshbooksProjects = timeTrackingResponse.data.projects;
      freshbooksProjects = _.filter(freshbooksProjects, (project) => !project.complete);
      return freshbooksProjects;
    } catch (e) {
      console.log(e);
    }
  });

  const fetchFBClients = useCallback(async (accountBusinessID) => {
    try {
      const { accessToken } = await fetchFBTokens();
      const identity = await getIdentity();
      accountBusinessID = accountBusinessID || (await getFBAccountBusinessID());
      const timeTrackingResponse = await axios.get(
        `https://api.freshbooks.com/accounting/account/${accountBusinessID}/users/clients?per_page=2000`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(timeTrackingResponse);
      return timeTrackingResponse.data.response.result.clients;
    } catch (e) {
      console.log(e);
    }
  });

  const fetchFBVendors = useCallback(async (accountBusinessID) => {
    try {
      const { accessToken } = await fetchFBTokens();
      accountBusinessID = accountBusinessID || (await getFBAccountBusinessID());
      const VendorResponse = await axios.get(
        `https://api.freshbooks.com/accounting/account/${accountBusinessID}/bill_vendors/bill_vendors?per_page=100`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(VendorResponse);
      return VendorResponse.data.response.result.bill_vendors;
    } catch (e) {
      console.log(e);
    }
  });

  const createFBProjectForToggl = useCallback(async () => {
    try {
      const { accessToken } = await fetchFBTokens();
      const identity = await getIdentity();
      const businessID = await getFBBusinessID();
      const timeTrackingResponse = await axios.post(
        `https://api.freshbooks.com/timetracking/business/${businessID}/projects`,
        {
          project: {
            title: 'Project for Toggl Sync',
            client_id: 96623,
            project_type: 'hourly_rate',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(timeTrackingResponse);
      return timeTrackingResponse.data.project.id;
    } catch (e) {
      console.log(e);
    }
  });

  const createFBClientForToggl = useCallback(async () => {
    try {
      const { accessToken } = await fetchFBTokens();
      const identity = await getIdentity();
      const accountBusinessID = await getFBAccountBusinessID();
      const timeTrackingResponse = await axios.post(
        `https://api.freshbooks.com/accounting/account/${accountBusinessID}/users/clients`,
        {
          client: {
            fname: 'Gelatech',
            lname: 'OneTracking',
            organization: 'Client for Toggl Sync',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(timeTrackingResponse);
      return timeTrackingResponse.data.response.result.client.id;
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    // fetchTimeTracking();
  }, []);

  return {
    timeTrackingList,
    fetchTimeTracking,
    fetchInvoices,
    fetchShopifyOrders,
    syncSelectedTimeTrackingRecordsToFB,
    syncSelectedShopifyOrdersToFB,
    fetchFBProjects,
    fetchFBClients,
    fetchFBVendors,
    createFBProjectForToggl,
    createFBClientForToggl,
    deleteSelectedRecords,
    fetchTogglProjects,
    setupTogglWebhook,
    getInvoiceActivities,
    joinTogglBeta,
    getFBInvoiceSharableLink,
    fetchInvoiceByUserID,
  };
};

export default useSync;
