import React, { useEffect, useState, useCallback, createContext, useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Base64 } from 'js-base64';
import {
  collection,
  query as dbQuery,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  limit,
  db,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { async } from '@firebase/util';
import moment from 'moment';
import useFirebase from './useFirebase';
import useFullstory from './useFullstory';
import { envVariable } from '../utils/constant';
import useQuery from './useQuery';
import { FBAppLoginURL } from '../routes';

const AuthContext = createContext({
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  identity: null,
});

export const PlanName = {
  free: 'Free Plan',
  plus: 'Plus Plan',
};

export const FBAppName = {
  fb_syncing_shopify_app: 'FreshBooks Syncing a Shopify App',
  shopify_syncing: 'Shopify Syncing',
  toggl_sycing: 'Toggl Syncing',
  invoice_in_style: 'Invoice In Style',
};

export const AuthProvider = (props) => {
  const { children } = props;
  // Firebase user object {id,data}
  const [user, setUser] = useState();
  const [togglAPIToken, setTogglAPIToken] = useState();
  // FreshBooks identity object
  const [identity, setIdentity] = useState();
  const [isShopifyAuth, setIsShopifyAuth] = useState(false);
  const [shopifyShop, setShopifyShop] = useState();
  const { firestoreDB } = useFirebase();
  const { FS } = useFullstory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [fbAppSetting, setFBAppSetting] = useState({
  //   app_name: FBAppName.toggl_sycing,
  //   app_client_id: envVariable.toggl_fb_app_client_id,
  //   app_client_secret: envVariable.toggl_fb_app_client_secret,
  //   app_redirect_url: envVariable.toggl_fb_app_redirect_url,
  // });

  const saveNewFreshBooksUserToDB = async (identity) => {
    try {
      const q = dbQuery(collection(firestoreDB, 'user'), where('freshbooks_id', '==', identity.id));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        // Add a new document with a generated id.
        const docRef = await addDoc(collection(firestoreDB, 'user'), {
          freshbooks_id: identity.id,
          email: identity.email,
          fb_access_token: getSavedToken().accessToken,
          fb_refresh_token: getSavedToken().refreshToken,
          shopify_access_token: getSavedShopifyToken(),
          current_app: getSavedToken().currentApp,
          fb_token_for_app: getSavedToken().currentApp,
        });
        // console.log(docRef);
        // setUser({ id: docRef.id, data: docRef.data() });
      } else {
        // always update the tokens
        // const docRef = doc(firestoreDB, 'user', where('freshbooks_id', '==', identity.id));
        querySnapshot.forEach((docSnapShot) => {
          setUser({ id: docSnapShot.id, data: docSnapShot.data() });

          const userRef = doc(firestoreDB, 'user', docSnapShot.id);
          updateDoc(userRef, {
            fb_access_token: getSavedToken().accessToken,
            fb_refresh_token: getSavedToken().refreshToken,
            shopify_access_token: getSavedShopifyToken(),
            current_app: getSavedToken().currentApp,
          });
        });
      }
      // Third party library tracking
      // Fullstory identity
      FS.identify(identity.id, { displayName: `${identity.first_name} ${identity.last_name}`, email: identity.email });

      // FreshChat idenity
      // To set unique user id in your system when it is available
      // window.fcWidget.setExternalId(identity.id);
      // To set user name
      // window.fcWidget.user.setFirstName(identity.first_name);
      // window.fcWidget.user.setLastName(identity.last_name);
      // To set user email
      // window.fcWidget.user.setEmail(identity.email);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  // get user object from db via freshbooks id
  const getUser = useCallback(async () => {
    let freshbooksID = identity?.id;
    if (!identity) {
      const fetchedIdentity = await getIdentity();
      if (!fetchedIdentity) return;
      freshbooksID = fetchedIdentity.id;
    }
    try {
      const q = dbQuery(collection(firestoreDB, 'user'), where('freshbooks_id', '==', freshbooksID));
      const querySnapshot = await getDocs(q);
      let returnUser = null;
      querySnapshot.forEach((doc) => {
        returnUser = { id: doc.id, data: doc.data() };
        console.log('user object:', returnUser);
        setUser(returnUser);
      });
      return returnUser;
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  }, [identity]);

  const updateSyncedTimeTracking = useCallback(
    async (syncedTimeTracking) => {
      const userObj = await getUser();
      try {
        const docRef = doc(firestoreDB, 'user', userObj.id);
        await updateDoc(docRef, { toggl_synced_tt: syncedTimeTracking });
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    },
    [firestoreDB]
  );

  const updateInvoiceActivities = useCallback(
    async (invoiceActivities) => {
      const userObj = await getUser();
      try {
        const docRef = doc(firestoreDB, 'user', userObj.id);
        await updateDoc(docRef, { invoice_in_style_activies: invoiceActivities });
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    },
    [firestoreDB]
  );

  const updateSyncedShopifyOrder = useCallback(
    async (syncedShopifyOrders) => {
      const userObj = await getUser();
      try {
        const docRef = doc(firestoreDB, 'user', userObj.id);
        await updateDoc(docRef, { shopify_synced_orders: syncedShopifyOrders });
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    },
    [firestoreDB]
  );

  const saveTogglAPIToken = async (token) => {
    try {
      // Save toggl api token to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_apitoken: token,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveTogglUserID = async (token) => {
    const userObj = await getUser();
    if (userObj.toggl_user_id) return;

    const togglUserResponse = await axios.get('https://api.track.toggl.com/api/v9/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${Base64.encode(`${token}:api_token`)}`,
      },
    });
    console.log('togglUserResponse', togglUserResponse);
    try {
      // Save toggl api token to db.
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_user_id: togglUserResponse.data.id,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveFBBusinessID = async (businessID, accountBusinessID) => {
    try {
      // Save toggl api token to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        fb_business_id: businessID,
        fb_account_business_id: accountBusinessID,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveTogglSyncToOneMode = async (syncToOneMode) => {
    try {
      // Save toggl api token to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_syncto_onemode: syncToOneMode,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveTogglSyncToProjectID = async (projectID) => {
    try {
      // Save the FB project id be synced to.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_syncto_projectid: projectID,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveShopifySyncToClientID = async (clientID) => {
    try {
      // Save the FB client id be synced to.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        shopify_syncto_clientid: clientID,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveTogglSyncToClientID = async (clientID) => {
    try {
      // Save the FB client id be synced to.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_syncto_clientid: clientID,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const saveTogglSyncedDate = async (syncedDate) => {
    try {
      // Save the FB client id be synced to.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_synced_date: syncedDate,
      });
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const getTogglSyncedDate = async () => {
    const userObj = await getUser();
    return userObj.data.toggl_synced_date;
  };

  const getTogglSyncToProjectID = async () => {
    const userObj = await getUser();
    return userObj.data.toggl_syncto_projectid;
  };

  const getTogglSyncToClientID = async () => {
    const userObj = await getUser();
    return userObj.data.toggl_syncto_clientid;
  };

  const getShopifySyncToClientID = async () => {
    const userObj = await getUser();
    return userObj.data.shopify_syncto_clientid;
  };

  const getFBBusinessID = async () => {
    const userObj = await getUser();
    return userObj.data.fb_business_id;
  };

  const getFBAccountBusinessID = async () => {
    const userObj = await getUser();
    return userObj.data.fb_account_business_id;
  };

  const getTogglSyncToOneMode = async () => {
    const userObj = await getUser();
    return userObj.data.toggl_syncto_onemode;
  };

  const getTogglAutoSyncMap = async () => {
    const userObj = await getUser();
    return userObj.data.toggl_autosync_project_map;
  };

  const saveTogglAutoSyncMap = async (autoSyncMap) => {
    try {
      // Save/update auto sync map info to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        toggl_autosync_project_map: autoSyncMap,
      });
    } catch (e) {
      console.error('Error adding/update document: ', e);
    }
  };

  const getPlanInfo = async () => {
    const userObj = await getUser();
    return userObj.data.plan;
  };

  const isPlan = async (planName) => {
    const userObj = await getUser();
    return (
      userObj.data.plan &&
      userObj.data.plan.plan_name === planName &&
      moment().isBefore(moment.unix(userObj.data.plan.expired_date))
    );
  };

  const getUserPreference = async (attributeName) => {
    const userObj = await getUser();
    return userObj.data[attributeName];
  };

  const saveUserPreference = async (attributeName, value) => {
    try {
      // Save/update plan info to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        [attributeName]: value,
      });
    } catch (e) {
      console.error('Error adding/update document: ', e);
    }
  };

  const savePlanInfo = async (planInfo) => {
    try {
      // Save/update plan info to db.
      const userObj = await getUser();
      await updateDoc(doc(firestoreDB, 'user', userObj.id), {
        plan: planInfo,
      });
    } catch (e) {
      console.error('Error adding/update document: ', e);
    }
  };

  const clearToken = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIdentity(null);
  };

  // Token should go with the app, different app will have different token
  const saveToken = (fbTokens) => {
    if (fbTokens) {
      localStorage.setItem('accessToken', fbTokens.accessToken);
      localStorage.setItem('refreshToken', fbTokens.refreshToken);
      localStorage.setItem('currentApp', fbTokens.currentApp);
      console.log(`Saved Token to local storage: ${fbTokens}`);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('currentApp');
    }
  };

  // save shopify access token into local storage
  const saveShopifyToken = (shopifyToken) => {
    if (shopifyToken) {
      localStorage.setItem('shopifyAccessToken', shopifyToken);
      console.log(`Saved Shopify Token to local storage: ${shopifyToken}`);
    } else {
      localStorage.removeItem('shopifyToken');
    }
  };

  const getSavedShopifyToken = () => localStorage.getItem('shopifyAccessToken');

  const getSavedToken = () => ({
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    currentApp: localStorage.getItem('currentApp'),
  });

  const query = useQuery();

  // will return accessToken as a object: {accessToken:xx, refreshToken:xx}
  const fetchFBTokens = useCallback(async () => {
    const path = window.location.pathname;
    let appName;
    let appClientID;
    let appClientSecret;
    let appRedirectURL;
    try {
      let fbTokens = getSavedToken();

      if (!fbTokens.accessToken && query.get('code')) {
        switch (path) {
          case FBAppLoginURL.invoice_in_style:
            appName = FBAppName.invoice_in_style;
            appClientID = envVariable.invoiceinstyle_fb_app_client_id;
            appClientSecret = envVariable.invoiceinstyle_fb_app_client_secret;
            appRedirectURL = envVariable.invoiceinstyle_fb_app_redirect_url;
            console.log('fetchFBTokens', 'setFBAppSetting to invoice in style app');
            break;
          case FBAppLoginURL.fb_syncing_shopify_app:
            appName = FBAppName.fb_syncing_shopify_app;
            appClientID = envVariable.shopify_fb_app_client_id;
            appClientSecret = envVariable.shopify_fb_app_client_secret;
            appRedirectURL = envVariable.fb_syncing_shopify_app_redirect_url;
            console.log('fetchFBTokens', 'setFBAppSetting to fb syncing shopify app');
            break;
          case FBAppLoginURL.shopify_syncing:
            appName = FBAppName.shopify_syncing;
            appClientID = envVariable.shopify_fb_app_client_id;
            appClientSecret = envVariable.shopify_fb_app_client_secret;
            appRedirectURL = envVariable.shopify_fb_app_redirect_url;
            console.log('fetchFBTokens', 'setFBAppSetting to shopify syncing app');
            break;
          case FBAppLoginURL.toggl_sycing:
            appName = FBAppName.toggl_sycing;
            appClientID = envVariable.toggl_fb_app_client_id;
            appClientSecret = envVariable.toggl_fb_app_client_secret;
            appRedirectURL = envVariable.toggl_fb_app_redirect_url;
            console.log('fetchFBTokens', 'setFBAppSetting to toggl syncing app');
            break;
          default:
            appName = FBAppName.toggl_sycing;
            appClientID = envVariable.toggl_fb_app_client_id;
            appClientSecret = envVariable.toggl_fb_app_client_secret;
            appRedirectURL = envVariable.toggl_fb_app_redirect_url;
            console.log('fetchFBTokens', 'setFBAppSetting to toggl syncing app');
            break;
        }

        const response = await axios.post('https://api.freshbooks.com/auth/oauth/token', {
          grant_type: 'authorization_code',
          client_id: appClientID,
          client_secret: appClientSecret,
          code: query.get('code'),
          redirect_uri: appRedirectURL,
        });
        fbTokens = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          currentApp: appName,
        };
        // console.log(`accessToken: ${accessToken}`);
        if (fbTokens) saveToken(fbTokens);
        // setFBAppSetting({
        //   app_name: appName,
        //   app_client_id: appClientID,
        //   app_client_secret: appClientSecret,
        //   app_redirect_url: appRedirectURL,
        // });
        setIsAuthenticated(true);
      }

      // setFBAppSetting({ ...fbAppSetting, app_name: fbTokens.currentApp });
      return fbTokens;
    } catch (err) {
      console.log('auth api call error');
      console.error(err);
      return { accessToken: null, refreshToken: null, currentApp: appName };
    }
  }, [query]);

  const fetchShopifyToken = useCallback(async () => {
    try {
      const userObj = await getUser();
      if (query.get('code')) {
        const response = await axios.post(`${envVariable.onetracking_backend_url}/shopifyauth`, {
          userID: userObj?.id,
          shop: query.get('shop'),
          code: query.get('code'),
        });
        setIsShopifyAuth(true);
        setShopifyShop(query.get('shop'));
        console.log('shopifyShop', query.get('shop'));
        console.log('Shopify AccessToken Response:', response);
        // remove all the url params
        if (searchParams.has('code')) {
          searchParams.delete('code');
          searchParams.delete('hmac');
          searchParams.delete('host');
          searchParams.delete('shop');
          searchParams.delete('state');
          searchParams.delete('timestamp');
          searchParams.append('isShopifyAuth', 'true');
          setSearchParams(searchParams);
        }
        if (response.data) saveShopifyToken(response.data.access_token);
      }
    } catch (err) {
      setIsShopifyAuth(false);
      console.log('auth api call error');
      console.error(err);
    }
  }, [query]);

  const getShopifyToken = useCallback(async () => {
    const userObj = await getUser();
    console.log('getShopifyToken userObj', userObj);
    if (userObj.data.shopify_access_token) {
      console.log('userObj.shopify_access_token', userObj.data.shopify_access_token);
      setShopifyShop(userObj.data.shopify_shop_url);
      setIsShopifyAuth(true);
      return userObj.data.shopify_access_token;
    }
    setIsShopifyAuth(false);
    return null;
  });

  const getIdentity = useCallback(async () => {
    try {
      if (identity) return identity;
      const { accessToken } = await fetchFBTokens();
      if (!accessToken) {
        console.log('fetch failed');
        return;
      }

      const UserResponse = await axios.get('https://api.freshbooks.com/auth/api/v1/users/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log('FB Identity:', UserResponse.data.response);
      const fetchIdentity = UserResponse.data.response;
      setIdentity(fetchIdentity);
      saveNewFreshBooksUserToDB(fetchIdentity);

      return fetchIdentity;
    } catch (err) {
      console.log('auth error');
      console.error(err);
      logout();
    }
  }, [fetchFBTokens]);

  const logout = () => {
    clearToken();
    setIsAuthenticated(false);
    let loginURL = '/';
    switch (getSavedToken().currentApp) {
      case FBAppName.invoice_in_style:
        loginURL = FBAppLoginURL.invoice_in_style;
        break;
      case FBAppName.fb_syncing_shopify_app:
        loginURL = FBAppLoginURL.fb_syncing_shopify_app;
        break;
      case FBAppName.shopify_syncing:
        loginURL = FBAppLoginURL.shopify_syncing;
        break;
      case FBAppName.toggl_sycing:
        loginURL = FBAppLoginURL.toggl_sycing;
        break;
      default:
        loginURL = '/';
    }
    window.location.replace(loginURL);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitialized: false,
        getUser,
        user,
        logout,
        identity,
        getIdentity,
        fetchFBTokens,
        clearToken,
        saveFBBusinessID,
        getFBBusinessID,
        isPlan,
        getPlanInfo,
        savePlanInfo,
        getTogglAutoSyncMap,
        saveTogglAutoSyncMap,
        togglAPIToken,
        setTogglAPIToken,
        saveTogglAPIToken,
        saveTogglUserID,
        updateSyncedTimeTracking,
        saveTogglSyncToProjectID,
        saveTogglSyncToClientID,
        saveTogglSyncToOneMode,
        getTogglSyncToProjectID,
        getTogglSyncToClientID,
        getFBAccountBusinessID,
        getTogglSyncToOneMode,
        saveTogglSyncedDate,
        getTogglSyncedDate,
        isShopifyAuth,
        shopifyShop,
        getShopifyToken,
        fetchShopifyToken,
        getShopifySyncToClientID,
        saveShopifySyncToClientID,
        updateSyncedShopifyOrder,
        saveUserPreference,
        getUserPreference,
        updateInvoiceActivities,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
