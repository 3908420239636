import PropTypes from 'prop-types';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
// import styles from './invoma_assets/css/invoma.module.css';
import './invoma_assets/css/invoma.scoped.css';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import logoWhite from './invoma_assets/img/logo_white.svg';
import logo from './invoma_assets/img/logo.svg';
import sign from './invoma_assets/img/sign.svg';
import {
  convertLineBreaker,
  getInvomaClientInfoHtml,
  getInvomaShipInfoHtml,
  getInvomaLineItemTableHtml,
  getInvomaNotesHtml,
  getInvomaPackingItemTableHtml,
  getInvomaOwnerInfoHtml,
  getInvomaTotalHtml,
  imgUrlToData,
} from './invoiceUtil';
import useSync from '../../../hooks/useSync';
// ----------------------------------------------------------------------

PackingSlipTemp.propTypes = {
  userInvoice: PropTypes.object,
  templateRef: PropTypes.object,
  isPreview: PropTypes.bool,
  editable: PropTypes.bool,
};

export default function PackingSlipTemp({ userInvoice, templateRef, isPreview, editable }) {
  const invoice = isPreview ? userInvoice : sampleInvoice;
  const ownBusiness = {
    ...invoice.ownerInfo,
    postal_code: invoice.ownerInfo.code,
    contact_email: invoice.ownerInfo.info_email,
  };
  const client = {
    ...invoice.clientInfo,
    postal_code: invoice.clientInfo.code,
    name: invoice.clientInfo.orgName,
    contact_email: invoice.clientContacts.length > 0 && invoice.clientContacts[0].email,
  };
  console.log('Invoice Obj', invoice);
  const { fetchFBVendors } = useSync();
  const [vendorList, setVendorList] = useState(null);
  const [selectedShipTo, setSelectedShipTo] = useState(
    invoice.additionalInfo ? invoice.additionalInfo.shiptoInfo : client
  );
  const [selectedShipper, setSelectedShipper] = useState(
    invoice.additionalInfo ? invoice.additionalInfo.shipperInfo : ownBusiness
  );
  const [logoData, setLogoData] = useState(null);
  const [orderNumber, setOrderNumber] = useState(invoice.additionalInfo?.orderNumber || invoice.invoiceNumber);

  imgUrlToData(invoice.presentation.logo, (myBase64) => {
    setLogoData(myBase64);
  });

  const InvoiceCardContainer = styled(Card)`
    max-width: 880px;
    position: relative;
  `;

  const fetchFBInfo = async () => {
    const fbVendors = await fetchFBVendors();
    return { fbVendors };
  };

  const getShipperOptions = () => {
    const shippers = [ownBusiness];
    vendorList?.forEach((vendor) => {
      shippers.push({ ...vendor, name: vendor.vendor_name, contact_email: vendor.primary_contact_email });
    });
    return shippers;
  };

  const getShipToOptions = () => {
    const client = {
      ...invoice.clientInfo,
      postal_code: invoice.clientInfo.code,
      name: invoice.clientInfo.orgName,
      contact_email: invoice.clientContacts.length > 0 && invoice.clientContacts[0].email,
    };
    const shipTos = [ownBusiness, client];
    return shipTos;
  };

  const handleShipperChange = (event, newShipper) => {
    console.log(newShipper);
    setSelectedShipper(newShipper);
    invoice.additionalInfo = { ...invoice.additionalInfo, shipperInfo: newShipper };
  };

  const handleShipToChange = (event, newShipTo) => {
    console.log(newShipTo);
    setSelectedShipTo(newShipTo);
    invoice.additionalInfo = { ...invoice.additionalInfo, shiptoInfo: newShipTo };
  };

  const handlePOChange = (event) => {
    setOrderNumber(event.target.value);
    invoice.additionalInfo = { ...invoice.additionalInfo, orderNumber: event.target.value };
  };

  useEffect(() => {
    if (editable)
      fetchFBInfo().then((data) => {
        setVendorList(data.fbVendors);
      });
  }, []);

  return (
    <Stack
      direction={{ sm: 'column-reverse', lg: 'row' }}
      spacing={1}
      justifyContent="center"
      alignItems={{ sm: 'center', lg: 'start' }}
    >
      <InvoiceCardContainer>
        <body className="tm_invoice_wrap">
          <div className="tm_invoice tm_style1 tm_po tm_type3" id="tm_download_section" ref={templateRef}>
            <div className="tm_shape_1">
              <svg width="850" height="151" viewBox="0 0 850 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M850 0.889398H0V150.889H184.505C216.239 150.889 246.673 141.531 269.113 124.872L359.112 58.0565C381.553 41.3977 411.987 32.0391 443.721 32.0391H850V0.889398Z"
                  fill="#007AFF"
                  fillOpacity="0.1"
                />
              </svg>
            </div>
            <div className="tm_shape_2">
              <svg width="850" height="151" viewBox="0 0 850 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 150.889H850V0.889408H665.496C633.762 0.889408 603.327 10.2481 580.887 26.9081L490.888 93.7224C468.447 110.381 438.014 119.74 406.279 119.74H0V150.889Z"
                  fill="#007AFF"
                  fillOpacity="0.1"
                />
              </svg>
            </div>
            <div className="tm_invoice_in">
              <div className="tm_invoice_head tm_align_center tm_mb20">
                <div className="tm_invoice_left">
                  <div className="tm_logo">
                    <img src={logoData || logoWhite} alt="Logo" />
                    {invoice.ownerInfo.name}
                  </div>
                </div>
                <div className="tm_invoice_right tm_text_right">
                  <div className="tm_primary_color tm_f40 tm_text_uppercase">Packing Slip</div>
                </div>
              </div>
              <div className="tm_invoice_info tm_mb20">
                <div className="tm_invoice_seperator">
                  <img src="assets/img/arrow_bg.svg" alt="" />
                </div>
                <div className="tm_invoice_info_list">
                  <p className="tm_invoice_number tm_m0">
                    Order No: <b className="tm_primary_color">{orderNumber}</b>
                  </p>
                  <p className="tm_invoice_date tm_m0">
                    Order Date: <b className="tm_primary_color">{invoice.issueDate}</b>
                  </p>
                  <div className="tm_invoice_info_list_bg tm_accent_bg_10" />
                </div>
              </div>
              <div className="tm_invoice_head tm_mb10">
                <div className="tm_invoice_left">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Shipper</b>
                  </p>
                  {selectedShipper ? (
                    getInvomaShipInfoHtml(selectedShipper)
                  ) : (
                    <Alert severity="error" sx={{ maxWidth: 300 }}>
                      Need to select who is shipper
                    </Alert>
                  )}
                </div>
                <div className="tm_invoice_right tm_text_right">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Ship To / Delivery:</b>
                  </p>
                  {selectedShipTo ? (
                    getInvomaShipInfoHtml(selectedShipTo)
                  ) : (
                    <Alert severity="error" sx={{ maxWidth: 300, lineHeight: '1.5em !important' }}>
                      Need to select where ship to
                    </Alert>
                  )}
                </div>
              </div>
              <div className="tm_table tm_style1 tm_mb30">
                <div className="tm_table_responsive">
                  <table className="tm_border_bottom">
                    <thead>
                      <tr className="tm_border_top">
                        <th className="tm_width_3 tm_semi_bold tm_primary_color tm_accent_bg_10">Item</th>
                        <th className="tm_width_4 tm_semi_bold tm_primary_color tm_accent_bg_10">Description</th>
                        <th className="tm_width_1 tm_semi_bold tm_primary_color tm_accent_bg_10">Qty</th>
                      </tr>
                    </thead>
                    {getInvomaPackingItemTableHtml(invoice)}
                  </table>
                </div>
                {invoice.notes && (
                  <div className="tm_invoice_footer">
                    <div className="tm_left_footer">
                      <p className="tm_mb2">
                        <b className="tm_primary_color">Notes:</b>
                      </p>
                      {getInvomaNotesHtml(invoice)}
                    </div>
                  </div>
                )}
              </div>
              {invoice.terms && (
                <div className="tm_padd_15">
                  <p className="tm_mb2">
                    <b className="tm_primary_color">Terms & Conditions:</b>
                  </p>
                  <ul className="tm_m0 tm_note_list">{convertLineBreaker(invoice.terms)}</ul>
                </div>
              )}
            </div>
          </div>
        </body>
      </InvoiceCardContainer>
      {editable && (
        <Stack direction={{ sm: 'row', lg: 'column' }} spacing={1}>
          <Card sx={{ minWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Order Number
              </Typography>
              <TextField
                variant="filled"
                fullWidth
                value={orderNumber}
                onChange={handlePOChange}
                label="Order Number"
              />
            </CardContent>
          </Card>
          <Card sx={{ minWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Select Shipper
              </Typography>
              <Autocomplete
                disableClearable
                id="vendor-select"
                options={getShipperOptions()}
                value={selectedShipper}
                getOptionLabel={(option) => option.name}
                onChange={handleShipperChange}
                renderInput={(params) => <TextField variant="filled" {...params} label="Vendor or your business" />}
              />
            </CardContent>
          </Card>
          <Card sx={{ minWidth: 345 }}>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Select Ship To
              </Typography>
              <Autocomplete
                disableClearable
                id="vendor-select"
                options={getShipToOptions()}
                value={selectedShipTo}
                getOptionLabel={(option) => option.name}
                onChange={handleShipToChange}
                renderInput={(params) => <TextField variant="filled" {...params} label="Client or your business" />}
              />
            </CardContent>
          </Card>
        </Stack>
      )}
    </Stack>
  );
}

const sampleInvoice = {
  id: 377322,
  invoiceNumber: '#LL93784',
  clientName: 'Lowell H. Dominguez',
  description: '',
  issueDate: '2023-04-28',
  dueDate: '2023-05-28',
  totalAmount: '1919',
  fbStatus: 'draft',
  fbPaymentStatus: 'unpaid',
  currency: 'CAD',
  lineItems: [
    {
      amount: {
        amount: '350.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Six web page designs and three times revision	.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 9,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Website Design',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 1,
      taxAmount1: '8',
      taxAmount2: '0',
      taxName1: 'GST',
      taxName2: null,
      taxNumber1: null,
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '350.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '600.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Convert pixel-perfect frontend and make it dynamic.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 10,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Web Development',
      qty: '1',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 2,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '600.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '400.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: '"Android & Ios Application Development."',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 11,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'App Development',
      qty: '2',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 3,
      taxAmount1: '13',
      taxAmount2: '0',
      taxName1: 'HST',
      taxName2: null,
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '200.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
    {
      amount: {
        amount: '300.00',
        code: 'CAD',
      },
      basecampid: 0,
      compounded_tax: false,
      date: null,
      description: 'Facebook, Youtube and Google Marketing.',
      expenseid: 0,
      invoiceid: 377245,
      lineid: 12,
      modern_project_id: null,
      modern_time_entries: [],
      name: 'Digital Marketing',
      qty: '3',
      retainer_id: null,
      retainer_period_id: null,
      taskno: 4,
      taxAmount1: '13',
      taxAmount2: '8',
      taxName1: 'HST',
      taxName2: 'GST',
      taxNumber1: '',
      taxNumber2: null,
      type: 0,
      unit_cost: {
        amount: '100.00',
        code: 'CAD',
      },
      updated: '2023-04-28 10:09:51',
    },
  ],
  ownerInfo: {
    bus_phone: '6479666000',
    city: 'Toronto',
    code: 'K0B1G5',
    country: 'Canada',
    currency_code: 'CAD',
    date: '2021-03-18 16:23:38',
    fax: '',
    info_email: 'owner.demo@gmail.com',
    mob_phone: '6479666570',
    modern_system: true,
    name: 'Gelatech',
    province: 'Ontario',
    street: '86 Paul Street',
    street2: null,
    subdomain: '',
    vat_name: null,
    vat_number: null,
    vis_state: 0,
  },
  ownerContact: {
    email: 'owner@gmail.com',
    fname: 'James',
    lname: 'Chen',
    organization: '',
    userid: 1,
  },
  clientInfo: {
    street: '84 Spilman Street',
    street2: '',
    orgName: 'Victor SaaS',
    province: 'Ontario',
    country: 'Canada',
  },
  clientContacts: [
    {
      contactid: -1,
      email: 'client.demo@gmail.com',
      fname: 'Rose',
      invoiceid: 377322,
      lname: 'Luke',
      userid: 278320,
    },
  ],
  terms: 'All claims relating to quantity or shipping errors.\nDelivery dates are not guaranteed and Seller.',
  notes: 'PayPal: client.demo@gmail.com\nAccount Number: 982182',
  presentation: {
    logo: 'https://api.freshbooks.com/uploads/images/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50Ijo2NDM3MTEzLCJvcmlnaW5hbF9maWxlbmFtZSI6Imljb24tZGFyay5wbmciLCJsZW5ndGgiOjUyOTI0LCJmaWxlbmFtZSI6InVwbG9hZC1jZjUwMzcxOWUzNzhkNjIwZjUwNGIwZGFkMDIzNjcxOWY3ZmVjMTI1IiwiYnVja2V0IjoidXBsb2FkcyIsImtleSI6Iidkb2NzLSctNjQzNzExMy91cGxvYWQtY2Y1MDM3MTllMzc4ZDYyMGY1MDRiMGRhZDAyMzY3MTlmN2ZlYzEyNSIsInV1aWQiOiIyMzMxODIyZS0yNjA5LTQyZmItYTNkYy1iZjQ1YmQ3OGE3Y2YifQ.IFiS4ROkwibdxyoiGwSMZ7O2wzsBJnMd5r-VGqUGk00',
    dateFormat: 'mm/dd/yyyy',
  },
};
