import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, CircularProgress, Stack, Divider, Avatar } from '@mui/material';
// components
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import SplitPayButton from '../components/SplitPayButton';
import { getCurrencyFormater } from '../sections/@dashboard/invoiceTemp/invoiceUtil';
import TemplatePreview from '../sections/@dashboard/invoice/TemplatePreview';
import Page from '../components/Page';
import useLink from '../hooks/useLink';
import useSync from '../hooks/useSync';
import useTemplates from '../hooks/useTemplates';
import templateComponentIndex from '../sections/@dashboard/invoiceTemp/TemplateComponentIndex';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function SharableLinkPage() {
  const { linkID } = useParams();
  const { getLinkDoc } = useLink();
  const { fetchInvoiceByUserID } = useSync();
  const [isLoading, setIsLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [templateID, setTemplateID] = useState(null);
  const [fbLink, setFBLink] = useState(null);
  const { downloadPDF, viewPlusOne, downloadPlusOne } = useTemplates();

  console.log('linkID', linkID);

  const fetchLinkDoc = async () => {
    const linkDoc = await getLinkDoc(linkID);
    console.log('linkDoc', linkDoc.data());
    const tempID = linkDoc.data()?.template_id;
    const invoiceObj = await fetchInvoiceByUserID(linkDoc.data().invoice_id, linkDoc.data().user_id);
    const fbSharableLink = linkDoc.data().fb_sharable_link;
    const addInfo = linkDoc.data().additional_info;
    invoiceObj.additionalInfo = { ...invoiceObj.additionalInfo, ...addInfo };
    console.log('invoiceObj', invoiceObj);
    return { invoiceObj, tempID, fbSharableLink };
  };

  const renderInvoice = () => {
    const SelectedTemplate = templateComponentIndex[templateID];
    return SelectedTemplate ? <SelectedTemplate userInvoice={invoice} isPreview templateRef={templateRef} /> : null;
  };

  const handlePrint = () => {
    window.print();
  };

  const templateRef = useRef(null);
  const handleDownload = () => {
    const fileName = `#${invoice.invoiceNumber} - ${templateID}`;
    // download invoice as pdf
    downloadPDF(fileName, templateRef);
    // incremente the download number for the selected invoice template
    downloadPlusOne(templateID);
  };

  const handlePayment = () => {
    window.open(fbLink, '_blank');
  };

  useEffect(() => {
    fetchLinkDoc().then((data) => {
      console.log('fetchLinkDoc', data);
      setIsLoading(false);
      setInvoice(data.invoiceObj);
      setTemplateID(data.tempID);
      setFBLink(data.fbSharableLink);
      // when open this link, increase view
      viewPlusOne(data.tempID);
    });
  }, []);

  return (
    <Page title="Gelatech Sharable Link">
      <Container
        sx={{
          '@media print': {
            '.no-print': { display: 'none !important' },
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2} mx={2} className="no-print">
          <Stack direction="column" alignItems="left">
            <Typography variant="h4" gutterBottom>
              {`Invoice ${invoice?.invoiceNumber}`}
            </Typography>
            <Typography variant="subtitle" gutterBottom>
              {invoice && invoice.fbStatus !== 'paid'
                ? `${getCurrencyFormater(invoice).format(invoice?.paymentInfo.outstanding.amount)} is due on ${moment(
                    invoice?.dueDate
                  ).format('MMM Mo, YYYY')}.`
                : `This invoice was paid in full`}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
            <Button variant="text" color="primary" onClick={handlePrint}>
              Print
            </Button>
            <Button variant="outlined" color="primary" onClick={handleDownload}>
              Download
            </Button>
            <SplitPayButton fbLink={fbLink} invoiceObj={invoice} />
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
              startIcon={
                <Avatar
                  variant="square"
                  alt="FreshBooks icon"
                  src="./static/icons/ic_freshbooks_white.png"
                  sx={{ height: 24, width: 24, objectFit: 'contain' }}
                />
              }
            >
              {invoice?.fbStatus === 'paid' ? 'View in FreshBooks' : 'Pay with FreshBooks'}
            </Button> */}
          </Stack>
        </Stack>
        <Divider className="no-print" />
        <ContentStyle>
          {isLoading || !templateID || !invoice ? <CircularProgress sx={{ marginY: 'auto' }} /> : renderInvoice()}
        </ContentStyle>
      </Container>
    </Page>
  );
}
