import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import _ from 'lodash';

// ----------------------------------------------------------------------

export function getSubTotal(invoice) {
  const subTotal = _.reduce(invoice.lineItems, (sum, lineItem) => sum + Number(lineItem.amount.amount), 0);
  return getCurrencyFormater(invoice).format(subTotal);
}

export function getTotalAmount(invoice) {
  return getCurrencyFormater(invoice).format(invoice.totalAmount);
}

export function getCurrencyFormater(invoice) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: invoice.currency,
    // currencyDisplay: 'narrowSymbol',
  });
}

export function getTaxIndex(invoice) {
  const taxIndex = {};
  const discountRate = 1 - (invoice.discountInfo ? Number(invoice.discountInfo.discountPercentage) * 0.01 : 0);
  console.log('discountRate', discountRate);
  invoice.lineItems.forEach((lineItem, index) => {
    if (lineItem.taxName1) {
      const taxTotal = lineItem.taxAmount1 * 0.01 * lineItem.amount.amount * discountRate;
      const preTax = taxIndex[lineItem.taxName1];
      if (!preTax) taxIndex[lineItem.taxName1] = { total: taxTotal, rate: lineItem.taxAmount1 };
      else taxIndex[lineItem.taxName1] = { total: preTax.total + taxTotal, rate: lineItem.taxAmount1 };
    }
    if (lineItem.taxName2) {
      const taxTotal = lineItem.taxAmount2 * 0.01 * lineItem.amount.amount * discountRate;
      const preTax = taxIndex[lineItem.taxName2];
      if (!preTax) taxIndex[lineItem.taxName2] = { total: taxTotal, rate: lineItem.taxAmount2 };
      else taxIndex[lineItem.taxName2] = { total: preTax.total + taxTotal, rate: lineItem.taxAmount2 };
    }
  });
  console.log('taxIndex', taxIndex);
  return taxIndex;
}

export function imgUrlToData(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export function convertLineBreaker(content) {
  if (!content) return null;
  if (content.indexOf('\n') === -1) return content;
  const scopeAttrs = getScopedIDAttributes();
  return content.split('\n').map((value, index) => (
    <li key={index} {...scopeAttrs}>
      {value}
    </li>
  ));
}

export function getInvomaOwnerInfoHtml(invoice) {
  return (
    <p>
      {invoice.ownerInfo.name} {invoice.ownerInfo.name && <br />}
      {invoice.ownerInfo.street} {invoice.ownerInfo.street && <br />}
      {invoice.ownerInfo.street2} {invoice.ownerInfo.street2 && <br />}
      {invoice.ownerInfo.country} {invoice.ownerInfo.code} {invoice.ownerInfo.country && <br />}
      {invoice.ownerContact.email}
    </p>
  );
}

export function getInvomaShipInfoHtml(shipInfo) {
  return (
    <p>
      {shipInfo.name} {shipInfo.name && <br />}
      {shipInfo.street} {shipInfo.street && <br />}
      {shipInfo.street2} {shipInfo.street2 && <br />}
      {shipInfo.country} {shipInfo.postal_code} {shipInfo.country && <br />}
      {shipInfo.contact_email}
    </p>
  );
}

export function getInvomaClientInfoHtml(invoice) {
  return (
    <p>
      {invoice.clientInfo.orgName} {invoice.clientInfo.orgName && <br />}
      {invoice.clientInfo.street} {invoice.clientInfo.street && <br />}
      {invoice.clientInfo.street2} {invoice.clientInfo.street2 && <br />}
      {invoice.clientInfo.country} {invoice.clientInfo.code} {invoice.clientInfo.country && <br />}
      {invoice.clientContacts.length > 0 && invoice.clientContacts[0].email}
    </p>
  );
}

export function getScopedIDAttributes() {
  // console.log('scopeID', document.getElementById('tm_download_section')?.attributes);
  const attrs = document.getElementById('tm_download_section')?.attributes;
  if (!attrs) return '';
  const scopeID = _.find(attrs, (value) => value.name.startsWith('data-v'))?.name;
  // console.log('scopeID', scopeID.name);
  // const scopeID = document.getElementById('tm_download_section')?.attributes[2].nodeName;
  return { [scopeID]: '' };
}

export function getInvomaPackingItemTableHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();

  return (
    <tbody {...scopeAttrs}>
      {invoice.lineItems.map((lineItem, index) => (
        <tr key={index}>
          <td className="tm_width_3" {...scopeAttrs}>{`${index + 1}. ${lineItem.name}`}</td>
          <td className="tm_width_4" {...scopeAttrs}>
            {lineItem.description}
          </td>
          <td className="tm_width_1" {...scopeAttrs}>
            {lineItem.qty}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export function getInvomaLineItemTableHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);

  return (
    <tbody {...scopeAttrs}>
      {invoice.lineItems.map((lineItem, index) => (
        <tr key={index}>
          <td className="tm_width_3" {...scopeAttrs}>{`${index + 1}. ${lineItem.name}`}</td>
          <td className="tm_width_4" {...scopeAttrs}>
            {lineItem.description}
          </td>
          <td className="tm_width_2" {...scopeAttrs}>
            {currencyFormatter.format(lineItem.unit_cost.amount)}
          </td>
          <td className="tm_width_1" {...scopeAttrs}>
            {lineItem.qty}
          </td>
          <td className="tm_width_2 tm_text_right" {...scopeAttrs}>
            {currencyFormatter.format(lineItem.amount.amount)}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export function getInvomaTimeItemTableHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);

  return (
    <tbody {...scopeAttrs}>
      {invoice.lineItems.map((lineItem, index) => (
        <tr key={index}>
          <td className="tm_width_7" {...scopeAttrs}>
            {`${index + 1}. ${lineItem.name}`}
            <p className="tm_secondary_color tm_f14" {...scopeAttrs}>
              {lineItem.description}
            </p>
          </td>
          <td className="tm_width_2" {...scopeAttrs}>
            {currencyFormatter.format(lineItem.unit_cost.amount)}
          </td>
          <td className="tm_width_1" {...scopeAttrs}>
            {lineItem.qty}
          </td>
          <td className="tm_width_2 tm_text_right" {...scopeAttrs}>
            {currencyFormatter.format(lineItem.amount.amount)}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export function getInvomaReceiptLineItemTableHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);

  return (
    <tbody {...scopeAttrs}>
      {invoice.lineItems.map((lineItem, index) => (
        <tr key={index}>
          <td {...scopeAttrs}>{`${index + 1}.`}</td>
          <td {...scopeAttrs}>{lineItem.name}</td>
          <td {...scopeAttrs}>{currencyFormatter.format(lineItem.unit_cost.amount)}</td>
          <td {...scopeAttrs}>{lineItem.qty}</td>
          <td {...scopeAttrs}>{currencyFormatter.format(lineItem.amount.amount)}</td>
        </tr>
      ))}
    </tbody>
  );
}

export function getInvomaSimpleReceiptLineItemTableHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);

  return (
    <tbody {...scopeAttrs}>
      {invoice.lineItems.map((lineItem, index) => (
        <tr key={index}>
          <td {...scopeAttrs}>{lineItem.name}</td>
          <td {...scopeAttrs}>{currencyFormatter.format(lineItem.amount.amount)}</td>
        </tr>
      ))}
    </tbody>
  );
}

export function getInvomaTotalHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);
  const taxIndex = getTaxIndex(invoice);

  return (
    <table {...scopeAttrs}>
      <tbody {...scopeAttrs}>
        <tr {...scopeAttrs}>
          <td className="tm_width_3 tm_primary_color tm_border_none tm_bold" {...scopeAttrs}>
            Subtoal
          </td>
          <td className="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_bold" {...scopeAttrs}>
            {getSubTotal(invoice)}
          </td>
        </tr>
        {invoice.discountInfo && invoice.discountInfo.discountPercentage !== 0 && (
          <tr>
            <td className="tm_width_3 tm_danger_color tm_border_none tm_pt0" {...scopeAttrs}>
              Discount {`${invoice.discountInfo.discountPercentage}%`}
            </td>
            <td className="tm_width_3 tm_danger_color tm_text_right tm_border_none tm_pt0" {...scopeAttrs}>
              {currencyFormatter.format(invoice.discountInfo.discountTotal)}
            </td>
          </tr>
        )}
        {Object.entries(taxIndex).map((tax, index) => (
          <tr key={index} {...scopeAttrs}>
            <td className="tm_width_3 tm_primary_color tm_border_none tm_pt0" {...scopeAttrs}>
              {tax[0]} <span className="tm_ternary_color" {...scopeAttrs}>{`${tax[1].rate}%`}</span>
            </td>
            <td
              className="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_pt0"
              {...scopeAttrs}
            >{`+${currencyFormatter.format(tax[1].total)}`}</td>
          </tr>
        ))}
        <tr className="tm_border_top" {...scopeAttrs}>
          <td className="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_primary_color" {...scopeAttrs}>
            Grand Total{' '}
          </td>
          <td className="tm_width_3 tm_border_top_0 tm_bold tm_f16 tm_primary_color tm_text_right" {...scopeAttrs}>
            {currencyFormatter.format(invoice.totalAmount)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function getInvomaColourfulTotalHtml(invoice) {
  const scopeAttrs = getScopedIDAttributes();
  const currencyFormatter = getCurrencyFormater(invoice);
  const taxIndex = getTaxIndex(invoice);

  return (
    <table {...scopeAttrs}>
      <tbody {...scopeAttrs}>
        <tr {...scopeAttrs}>
          <td className="tm_width_3 tm_primary_color tm_border_none tm_bold" {...scopeAttrs}>
            Subtoal
          </td>
          <td className="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_bold" {...scopeAttrs}>
            {getSubTotal(invoice)}
          </td>
        </tr>
        {invoice.discountInfo && invoice.discountInfo.discountPercentage !== 0 && (
          <tr>
            <td className="tm_width_3 tm_danger_color tm_border_none tm_pt0" {...scopeAttrs}>
              Discount {`${invoice.discountInfo.discountPercentage}%`}
            </td>
            <td className="tm_width_3 tm_danger_color tm_text_right tm_border_none tm_pt0" {...scopeAttrs}>
              {currencyFormatter.format(invoice.discountInfo.discountTotal)}
            </td>
          </tr>
        )}
        {Object.entries(taxIndex).map((tax, index) => (
          <tr key={index} {...scopeAttrs}>
            <td className="tm_width_3 tm_primary_color tm_border_none tm_pt0" {...scopeAttrs}>
              {tax[0]} <span className="tm_ternary_color" {...scopeAttrs}>{`${tax[1].rate}%`}</span>
            </td>
            <td
              className="tm_width_3 tm_primary_color tm_text_right tm_border_none tm_pt0"
              {...scopeAttrs}
            >{`+${currencyFormatter.format(tax[1].total)}`}</td>
          </tr>
        ))}
        <tr {...scopeAttrs}>
          <td
            className="tm_width_3 tm_border_top_0 tm_bold tm_f18 tm_accent_color tm_accent_bg_10 tm_radius_6_0_0_6"
            {...scopeAttrs}
          >
            Grand Total{' '}
          </td>
          <td
            className="tm_width_3 tm_border_top_0 tm_bold tm_f18 tm_text_right tm_accent_color tm_accent_bg_10 tm_radius_0_6_6_0"
            {...scopeAttrs}
          >
            {currencyFormatter.format(invoice.totalAmount)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function getInvomaNotesHtml(invoice) {
  if (invoice.notes && invoice.notes.indexOf('\n') === -1) return invoice.notes;

  const scopeAttrs = getScopedIDAttributes();
  return (
    <ul className="tm_m0 tm_note_list" {...scopeAttrs}>
      {convertLineBreaker(invoice.notes)} <br />
    </ul>
  );
}
