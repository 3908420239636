import _, { filter, isEmpty, map } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { jsPDF as JsPDF } from 'jspdf';
import { Link as RouterLink } from 'react-router-dom';
import html2pdf from 'html2pdf.js/dist/html2pdf';
// material
import {
  Menu,
  Link,
  IconButton,
  Alert,
  Paper,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  FilledInput,
  InputAdornment,
  Select,
  Snackbar,
  InputLabel,
  FormControl,
  MenuItem,
  Checkbox,
  TableRow,
  TableBody,
  TextField,
  TableCell,
  Container,
  Typography,
  Grid,
  TableContainer,
  TablePagination,
  CircularProgress,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
  Box,
  Slide,
  FormHelperText,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { height } from '@mui/system';
import { shopifyApi } from '@shopify/shopify-api';
import GeneralReceiptTemp from '../sections/@dashboard/invoiceTemp/GeneralReceiptTemp';
import TemplatePreview from '../sections/@dashboard/invoice/TemplatePreview';
import TemplateComponentIndex from '../sections/@dashboard/invoiceTemp/TemplateComponentIndex';
import GeneralDarkTemp from '../sections/@dashboard/invoiceTemp/GeneralDarkTemp';
import useTemplates from '../hooks/useTemplates';
import GeneralTemp from '../sections/@dashboard/invoiceTemp/GeneralTemp';
import HourlyWorkTemp from '../sections/@dashboard/invoiceTemp/HourlyWorkTemp';
import TemplateGrid from '../sections/@dashboard/invoice/TemplateGrid';
import { envVariable } from '../utils/constant';
import ShopifyLogo from '../components/ShopifyLogo';
import useAuth, { FBAppName } from '../hooks/useAuth';
import useSync, { statusCopyMap } from '../hooks/useSync';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import useQuery from '../hooks/useQuery';
import useLink from '../hooks/useLink';
// mock
// import templates from '../_mock/templates';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'clientName', label: 'Client / Invoice #', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'issueDate', label: 'Issued Date / Due', alignRight: false },
  { id: 'fbStatus', label: 'Amount / Status', alignRight: true },
];
// ----------------------------------------------------------------------

const steps = ['Select a template to use', 'Generate your invoice in style'];

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'createDate') {
    if (moment(b[orderBy]).isBefore(a[orderBy])) return -1;
    if (moment(b[orderBy]).isAfter(a[orderBy])) return 1;
    if (moment(b[orderBy]).isSame(a[orderBy])) return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array || array.length === 0) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (record) =>
        record.clientName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        record.invoiceNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        record.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function InvoiceInStyle() {
  const { identity, getUser, saveFBBusinessID, updateInvoiceActivities } = useAuth();

  const [invoiceList, setInvoiceList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isReconnect, setIsReconnect] = useState(false);

  const {
    syncSelectedShopifyOrdersToFB,
    fetchFBClients,
    fetchInvoices,
    deleteSelectedRecords,
    getInvoiceActivities,
    getFBInvoiceSharableLink,
  } = useSync();

  const { getTemplates, viewPlusOne, downloadPlusOne, sendPlusOne, downloadPDF, sendInvoiceEmail } = useTemplates();
  const { generateInvoiceSharableLink, getLinkDoc } = useLink();
  const [invoiceSharableLink, setInvoiceSharableLink] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTempID, setSelectedTempID] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [toEmails, setToEmails] = useState([]);
  const [emailNotes, setEmailNotes] = useState(null);
  const [isSendEmailButtonLoading, setIsSendEmailButtonLoading] = useState(false);

  const [fbProjects, setFBProjects] = useState([]);
  const [projectID, setProjectID] = useState(0);
  const [syncToProject, setSyncToProject] = useState(null);

  const [fbClients, setFBClients] = useState([]);
  const [clientID, setClientID] = useState(0);

  const [fbBusinesses, setFBBusinesses] = useState([]);
  const [businessID, setBusinessID] = useState(0);
  const [accountBusinessID, setAccountBusinessID] = useState(0);

  const [syncedDate, setSyncedDate] = useState();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createDate');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState('');
  const oldestSinceDate = moment().subtract(3, 'months').add(2, 'days').format('YYYY-MM-DD');

  useEffect(() => {
    async function fetchUserObjFromDB() {
      const userObj = await getUser();
      console.log(userObj.data.toggl_apitoken);
      reloadInvoiceTable();
      setProjectID(userObj.data.toggl_syncto_projectid || 0);
      // setClientID(userObj.data.toggl_syncto_clientid || 0);

      const businessArray = identity?.business_memberships?.map((business) => ({
        id: business.business.id,
        account_id: business.business.account_id,
        name: business.business.name,
        role: business.role,
        status: business.business.status,
      }));
      setFBBusinesses(businessArray);
      console.log('businessArray', businessArray);
      const tempBusinessID = userObj.data.fb_business_id || businessArray[0]?.id;
      setBusinessID(tempBusinessID);
      console.log('businessID', tempBusinessID);
      const tempAccountBusinessID = userObj.data.fb_account_business_id || businessArray[0]?.account_id;
      setAccountBusinessID(tempAccountBusinessID);
      if (!userObj.data.fb_business_id || !userObj.data.fb_account_business_id) {
        saveFBBusinessID(tempBusinessID, tempAccountBusinessID);
        handleSettingDialogOpen();
      }
    }
    if (businessID === 0) fetchUserObjFromDB();
  });

  const fetchInvoiceList = async () => {
    setIsLoading(true);
    const invoices = await fetchInvoices();
    setInvoiceList(invoices);
    console.log('fetchInvoiceList ', invoices);
    setIsLoading(false);
    showSnackMsg('Refresh success!');
  };

  const fetchTemplates = async () => {
    let sortedTemplates = await getTemplates();
    sortedTemplates = _.orderBy(sortedTemplates, 'views', 'desc');
    setTemplates(sortedTemplates);
  };

  useEffect(() => {
    if (!invoiceList || invoiceList.length === 0) fetchInvoiceList();
    if (!templates || templates.length === 0) fetchTemplates();
  }, []);

  const query = useQuery();

  useEffect(() => {
    console.log('businessID', businessID);
    console.log('fbBusinesses', fbBusinesses);
  });

  const getStatusNum = (ttlist, status) => {
    if (!ttlist || ttlist.length === 0) return 0;
    let totalNum = 0;
    ttlist.forEach((tt) => {
      if (tt.status === status) totalNum += 1;
    });
    return totalNum;
  };

  const handleDatePickerChange = (newValue) => {
    console.log(newValue);
    setSyncedDate(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClientChange = (event, newValue) => {
    console.log(newValue);
    setClientID(newValue.id);
  };

  const handleBusinessChange = (event) => {
    const businesIDToFind = event.target.value;
    const foundBusinessObj = _.find(fbBusinesses, (businessObj) => businessObj.id === businesIDToFind);
    setBusinessID(foundBusinessObj.id);
    setAccountBusinessID(foundBusinessObj.account_id);
  };

  // const handleClientChange = (event) => {
  //   setClientID(event.target.value);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(filteredRecords);
      return;
    }
    setSelected([]);
  };

  const handleSyncAll = (event) => {
    syncSelectedShopifyOrdersToFB(invoiceList).then(() => {
      showSnackMsg(`All records sync successfully!`);
      setSelected([]);
    });
  };

  const handleSyncSelected = (event) => {
    setSyncDialogOpen(true);
  };

  const handleDeleteSelected = (event) => {
    deleteSelectedRecords(selected).then(() => {
      setSelected([]);
      reloadInvoiceTable();
    });
    setLinkDialogOpen(false);
  };

  const handleRowSelect = (record) => {
    console.log('Selected record', record);
    const isSelected = selected.indexOf(record) === -1;
    let newSelected = _.clone(selected);
    if (isSelected) newSelected.push(record);
    else {
      newSelected = _.reject(newSelected, (tt) => tt.id === record.id);
    }
    setSelected(newSelected);
  };

  const handleRowClick = (record) => {
    console.log('Selected record', record);
    setSelectedInvoice(record);
    setSyncDialogOpen(true);
  };

  const isAllNewRecord = (selectedRecord) =>
    _.every(
      selectedRecord,
      (record) => record.status === statusCopyMap.ready_to_sync || record.status === statusCopyMap.error
    );

  const isAllSyncedRecord = (selectedRecord) =>
    _.every(selectedRecord, (record) => record.status === statusCopyMap.synced);

  const reloadInvoiceTable = () => {
    fetchInvoiceList();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [senddialogOpen, setDialogOpen] = useState(false);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [syncDialogOpen, setSyncDialogOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettingDialogOpen = (event) => {
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    setIsReconnect(false);
    setDialogOpen(false);
  };

  const handleLinkDialogClose = (event) => {
    setIsLinkCopied(false);
    setLinkDialogOpen(false);
  };

  const handleEmailDialogClose = (event) => {
    setEmailDialogOpen(false);
  };

  const handleRefresh = (event) => {
    reloadInvoiceTable();
  };

  const handleSettingDialogConfirm = useCallback(async () => {
    setIsReconnect(false);
    saveFBBusinessID(businessID, accountBusinessID);

    // fetch again after close the dialog
    reloadInvoiceTable();
    setDialogOpen(false);
  });

  const handleSyncDialogConfirm = useCallback(async () => {
    setSyncDialogOpen(false);
  });

  const handleTemplateDialogClose = () => {
    setSyncDialogOpen(false);
    setSelectedInvoice(null);
    setSelectedTempID(null);
    setActiveStep(0);
  };

  const showSnackMsg = (msg) => {
    setSnackBarOpen(true);
    setSnackBarMsg(msg);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handleTempSelection = (tempID) => {
    console.log('TempID', tempID);
    setActiveStep(1);
    setSelectedTempID(tempID);
    // Increase the view number
    viewPlusOne(tempID);
  };

  const addActivityToDB = async (actionName) => {
    const invoiceActivities = await getInvoiceActivities();
    const invoiceToUpdate = invoiceActivities[selectedInvoice.id];
    const newActivity = {
      action: actionName,
      tempID: selectedTempID,
      invoiceID: selectedInvoice.id,
      date: moment().unix(),
    };
    if (!invoiceToUpdate) invoiceActivities[selectedInvoice.id] = [newActivity];
    else {
      invoiceActivities[selectedInvoice.id].push(newActivity);
    }
    console.log(invoiceActivities);
    updateInvoiceActivities(invoiceActivities);
  };

  const findTemplateByID = (tempID) => _.find(templates, (temp) => temp.id === tempID);

  const templateRef = useRef(null);
  const handleDownloadPDF = async () => {
    const fileName = `#${selectedInvoice.invoiceNumber} - ${selectedTempID}`;
    // download invoice as pdf
    downloadPDF(fileName, templateRef);
    // incremente the download number for the selected invoice template
    downloadPlusOne(selectedTempID);
    // save the download activity to db
    addActivityToDB('download');
  };

  // generate/get Link, linkid = hash(userid,templateid,invoiceid)
  // if the status is not sent, Mark invoice as sent and get fb invoice sharable link
  const handleSendEvent = async (actionType) => {
    const userObj = await getUser();
    const sharableLink = await generateInvoiceSharableLink(
      userObj.id,
      selectedInvoice,
      selectedTempID,
      FBAppName.invoice_in_style
    );
    console.log(sharableLink);
    setInvoiceSharableLink(sharableLink);

    // Increase send number in db
    sendPlusOne(selectedTempID);
    // save the activity action to db
    addActivityToDB(actionType);
  };

  const handleSendEmail = async () => {
    console.log('handleSendEmail', toEmails, invoiceSharableLink, emailNotes);
    // call backend to send
    sendInvoiceEmail(selectedInvoice, toEmails, invoiceSharableLink, emailNotes).then(() => {
      setEmailDialogOpen(false);
      showSnackMsg('Email sent success!');
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(invoiceSharableLink);
    setIsLinkCopied(true);
  };

  const getFBStatusChipColor = (statusName) => {
    switch (statusName) {
      case 'created':
      case 'draft':
        return 'default';
      case 'sent':
      case 'viewed':
      case 'retry':
      case 'partial':
        return 'warning';
      case 'success':
      case 'autopaid':
      case 'paid':
        return 'success';
      case 'failed':
      case 'overdue':
        return 'error';
      default:
        return 'info';
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoiceList.length) : 0;

  const filteredRecords = applySortFilter(invoiceList, getComparator(order, orderBy), filterName);

  const isRecordNotFound = filterName !== '' && filteredRecords.length === 0;

  return (
    <Page title="Invoice In Style">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Stack direction="column" alignItems="left">
            <Typography variant="h4" gutterBottom>
              Hi {identity?.first_name}, Welcome back
            </Typography>
            <Typography variant="subtitle" gutterBottom>
              Customize your invoices with stylish templates
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
            <Button
              variant="outlined"
              onClick={handleSettingDialogOpen}
              startIcon={
                <Avatar
                  variant="square"
                  alt="FreshBooks icon"
                  src="./static/icons/ic_freshbooks.png"
                  sx={{ height: 24, width: 24, objectFit: 'contain' }}
                />
              }
            >
              FreshBooks Settings
            </Button>
            {/* <Button
              variant="contained"
              onClick={handleSettingDialogOpen}
              startIcon={<Iconify icon="ic:baseline-add" />}
            >
              New Document
            </Button> */}
          </Stack>
        </Stack>

        {/* <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Ready to Sync"
              total={getStatusNum(InvoiceList, statusCopyMap.ready_to_sync)}
              color="info"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Synced to FreshBooks"
              total={getStatusNum(InvoiceList, statusCopyMap.synced)}
              color="success"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary title="Sync Error" total={getStatusNum(InvoiceList, statusCopyMap.error)} color="error" />
          </Grid>
        </Grid> */}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            filterPlaceholder={'Search Client / Description / invoice #...'}
            onRefresh={handleRefresh}
            onSyncSelected={handleSyncSelected}
            onDeleteSelected={
              isAllSyncedRecord(selected)
                ? () => {
                    setLinkDialogOpen(true);
                  }
                : null
            }
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredRecords.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!isLoading &&
                    filteredRecords.length !== 0 &&
                    filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => {
                      const {
                        id,
                        invoiceNumber,
                        clientName,
                        description,
                        issueDate,
                        dueDate,
                        totalAmount,
                        fbStatus,
                        fbPaymentStatus,
                        currency,
                      } = record;
                      const currencyFormatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency,
                      });

                      const displayTotalAmount = currencyFormatter.format(totalAmount);
                      const isItemSelected = selected.indexOf(record) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={() => handleRowClick(record)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                            <Typography variant="subtitle2" noWrap>
                              {clientName || '<No Client>'}
                            </Typography>
                            <Typography variant="caption" noWrap>
                              {invoiceNumber || '<Not Available>'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ maxWidth: 500 }}>
                            {description || '<Not Available>'}
                          </TableCell>
                          <TableCell component="th" scope="row" sx={{ maxWidth: 250 }}>
                            <Typography variant="subtitle2" noWrap>
                              {issueDate || '<Not Available>'}
                            </Typography>
                            <Typography variant="caption" noWrap>
                              {moment(dueDate).fromNow() || '<Not Available>'}
                            </Typography>
                          </TableCell>
                          <TableCell align="right" scope="row">
                            <Typography variant="subtitle2" noWrap>
                              {displayTotalAmount || '<Not Available>'}
                            </Typography>
                            <Label variant="ghost" color={getFBStatusChipColor(fbStatus)}>
                              {fbStatus}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && !isLoading && (
                    <TableRow style={{ height: 56 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isRecordNotFound && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1.5,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleSettingDialogOpen}>
              <Avatar alt="Toggl icon" src="./static/icons/toggl.png" /> Toggl
            </MenuItem>
            <MenuItem>
              <Avatar alt="Harvest icon" src="./static/icons/harvest.png" /> Harvest
            </MenuItem>
          </Menu>
        </Card>

        {/* Select Template dialog */}
        <Dialog
          open={syncDialogOpen}
          onClose={() => setSyncDialogOpen(false)}
          // fullWidth
          // maxWidth="lg"
          TransitionComponent={Transition}
          fullScreen
          scroll="paper"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title" variant="h4">{`Step 1: Select a template to use`}</DialogTitle> */}
          {/* Stepper to indicate */}
          <DialogTitle id="alert-dialog-title">
            <Stack direction="row">
              {/* Make up space */}
              <Button startIcon={<Iconify icon="eva:close-fill" />} variant="text" sx={{ visibility: 'hidden' }}>
                Close
              </Button>
              <Box sx={{ marginX: 'auto', maxWidth: '750px', width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography variant="h5">{label}</Typography>
                        {activeStep >= index && index === 0 && (
                          <Typography
                            variant="subtitle"
                            color="textSecondary"
                          >{`invoice#: ${selectedInvoice?.invoiceNumber}`}</Typography>
                        )}
                        {activeStep >= index && index === 1 && (
                          <Typography variant="subtitle" color="textSecondary">{`Template: ${
                            findTemplateByID(selectedTempID)?.name
                          }`}</Typography>
                        )}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <Button startIcon={<Iconify icon="eva:close-fill" />} variant="text" onClick={handleTemplateDialogClose}>
                Close
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ px: 3, backgroundColor: '#f2f2f2' }} dividers>
            {activeStep === 0 ? (
              <TemplateGrid templates={templates} onTempClick={handleTempSelection} />
            ) : (
              <TemplatePreview templateID={selectedTempID} invoice={selectedInvoice} templateRef={templateRef} />
            )}
          </DialogContent>
          <DialogActions sx={{ px: 3 }}>
            <Stack sx={{ width: '100%', marginX: 'auto' }} direction="row" justifyContent="space-between">
              {activeStep === 0 && (
                <Button
                  startIcon={<Iconify icon="eva:close-fill" />}
                  variant="outlined"
                  onClick={handleTemplateDialogClose}
                >
                  Close
                </Button>
              )}
              {activeStep !== 0 && (
                <Button variant="outlined" onClick={() => setActiveStep(0)}>
                  Back
                </Button>
              )}
              <Stack direction="row" spacing={3}>
                <Button variant="text" onClick={handleDownloadPDF} disabled={activeStep === 0}>
                  Download PDF
                </Button>
                <Button
                  variant="outlined"
                  onClick={async () => {
                    await handleSendEvent('link');
                    setLinkDialogOpen(true);
                    console.log('on send link', selectedInvoice);
                  }}
                  disabled={activeStep === 0}
                >
                  Send Link
                </Button>
                <LoadingButton
                  variant="contained"
                  loading={isSendEmailButtonLoading}
                  onClick={async () => {
                    setIsSendEmailButtonLoading(true);
                    await handleSendEvent('email').then(() => {
                      setIsSendEmailButtonLoading(false);
                    });
                    setEmailDialogOpen(true);
                    setToEmails([selectedInvoice?.clientContacts[0].email]);
                  }}
                  disabled={activeStep === 0}
                >
                  Send Email
                </LoadingButton>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>

        {/* Mark as sent dialog */}
        {/* <Dialog
          open={markAsSentDialogOpen}
          onClose={handleMarkAsSentDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Send sync record(s)?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Creating a shareable link will mark this draft invoice as sent.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose}>Close</Button>
            <Button onClick={handleDeleteSelected} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog> */}

        {/* Send Link Dialog */}
        <Dialog
          fullWidth
          open={linkDialogOpen}
          onClose={handleLinkDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Stack direction="column" alignItems="center" my={3} px={5} spacing={2}>
            <Typography variant="h4">Voilà - here's your link</Typography>
            <Typography variant="subtitle">You can copy, paste and share this link with your client.</Typography>
            <FormControl fullWidth variant="filled">
              <InputLabel htmlFor="filled-adornment-link">Sharable Link</InputLabel>
              <FilledInput
                readOnly
                id="filled-adornment-link"
                value={isLinkCopied ? 'Copied!' : invoiceSharableLink}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleCopyLink} edge="end">
                      <Iconify icon="clarity:copy-to-clipboard-line" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Stack direction="column" sx={{ width: '100%' }} alignItems="center" spacing={2}>
              <Button sx={{ px: 6, mt: 3 }} onClick={handleCopyLink} variant="contained" size="large">
                Copy Link
              </Button>
              <Button onClick={handleLinkDialogClose}>Close</Button>
            </Stack>
          </Stack>
        </Dialog>

        {/* Send Email Dialog */}
        <Dialog
          fullWidth
          open={emailDialogOpen}
          onClose={handleEmailDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Stack direction="column" alignItems="center" mt={4} mb={3} px={5} spacing={5}>
            <Stack direction="column" alignItems="center" spacing={1}>
              <Typography variant="h4">Send Invoice 000005 via Email</Typography>
              <Typography variant="subtitle">Please select the client email or enter email address below.</Typography>
            </Stack>
            <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
              <Autocomplete
                multiple
                fullWidth
                id="tags-filled"
                options={
                  selectedInvoice && [
                    selectedInvoice?.ownerContact.email,
                    ...selectedInvoice?.clientContacts.map((contact) => contact.email),
                  ]
                }
                defaultValue={[selectedInvoice?.clientContacts[0].email]}
                // freeSolo
                onChange={(e, v, r, d) => {
                  console.log('email values', v);
                  setToEmails(v);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Email Address" placeholder="Enter Email..." />
                )}
              />
              <TextField
                variant="outlined"
                label="Notes"
                fullWidth
                onChange={(e) => {
                  setEmailNotes(e.target.value);
                }}
                placeholder="Say thank you or add speical notes..."
              />
            </Stack>
            <Stack direction="column" sx={{ width: '100%' }} alignItems="center" spacing={2}>
              <Button sx={{ px: 6, mt: 1 }} onClick={handleSendEmail} variant="contained" size="large">
                Send Email
              </Button>
              <Button onClick={handleEmailDialogClose}>Close</Button>
            </Stack>
          </Stack>
        </Dialog>

        {/* Setting dialog */}
        <Dialog open={senddialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
          <DialogTitle>{'FreshBooks Settings'}</DialogTitle>
          <DialogContent>
            <DialogContentText>{'Adjust your FreshBooks settings here'}</DialogContentText>
            <Stack direction="column" sx={{ mb: 3, mt: 3 }} spacing={2}>
              <FormControl variant="filled">
                <InputLabel id="business-select-label">FreshBooks Business</InputLabel>
                <Select
                  labelId="business-select-label"
                  id="business-select"
                  value={businessID}
                  onChange={handleBusinessChange}
                >
                  {fbBusinesses &&
                    fbBusinesses.map((business, index) => (
                      <MenuItem value={business.id} key={index}>
                        <Stack direction="column">
                          <Typography variant="body">{business.name}</Typography>
                          <Typography variant="caption">{business.role}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSettingDialogConfirm}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackBarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          message={snackBarMsg}
          onClose={handleSnackBarClose}
          sx={{ bottom: { xs: 66 } }}
          action={
            <>
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleSnackBarClose}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </>
          }
        />
      </Container>
    </Page>
  );
}
