import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Avatar } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import Iconify from './Iconify';

const options = ['Pay with FreshBooks', 'Pay with Authorize.net', 'Pay with Venmo'];
const buttonIconURL = [
  './static/icons/ic_freshbooks_white.png',
  './static/icons/ic_authorizenet.jpeg',
  './static/icons/ic_venmo.png',
];
const menuIconsURL = [
  './static/icons/ic_freshbooks.png',
  './static/icons/ic_authorizenet.jpeg',
  './static/icons/ic_venmo.png',
];

SplitPayButton.propTypes = {
  invoiceObj: PropTypes.object,
  fbLink: PropTypes.string,
};

export default function SplitPayButton({ invoiceObj, fbLink }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handlePayment = (index) => {
    switch (index) {
      case 0:
        // Pay with FreshBooks
        window.open(fbLink, '_blank');
        break;
      case 1:
        // Pay with Authorize.net
        // window.open(fbLink, '_blank');
        enqueueSnackbar('Feature coming soon!');
        break;
      case 2:
        // Pay with Venmo
        enqueueSnackbar('Feature coming soon!');
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
    handlePayment(selectedIndex);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    console.info(`You clicked ${options[index]}`);
    handlePayment(index);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} />
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button
          onClick={handleClick}
          startIcon={
            <Avatar
              variant="square"
              alt="payment icon"
              src={buttonIconURL[selectedIndex]}
              sx={{ height: 24, width: 24, mr: 1, objectFit: 'contain' }}
            />
          }
        >
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <Iconify icon="material-symbols:arrow-drop-down" sx={{ width: 24, height: 24 }} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Avatar
                        variant="square"
                        alt="payment icon"
                        src={menuIconsURL[index]}
                        sx={{ height: 24, width: 24, mr: 1, objectFit: 'contain' }}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
