import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
// component
import SyncStatusLabel from '../../../components/SyncStatusLabel';
import Iconify from '../../../components/Iconify';
import { statusCopyMap } from '../../../hooks/useSync';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  minWidth: 350,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  filterPlaceholder: PropTypes.string,
  onFilterName: PropTypes.func,
  filterByStatus: PropTypes.string,
  onStatusFilterChange: PropTypes.func,
  onRefresh: PropTypes.func,
  onSyncSelected: PropTypes.func,
  onDeleteSelected: PropTypes.func,
};

export default function UserListToolbar({
  numSelected,
  filterName,
  filterPlaceholder,
  onFilterName,
  filterByStatus,
  onStatusFilterChange,
  onRefresh,
  onSyncSelected,
  onDeleteSelected,
}) {
  console.log(!onDeleteSelected);
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Stack direction="row" alignItems="left" justifyContent="start" spacing={2}>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={filterPlaceholder}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          {filterByStatus && (
            <FormControl fullWidth>
              <InputLabel id="filter-by-status-select-label">Filter By Status</InputLabel>
              <Select
                sx={{ width: 160 }}
                labelId="filter-by-status-select-label"
                id="filter-by-status-select"
                value={filterByStatus}
                defaultValue={statusCopyMap.ready_to_sync}
                label="Filter By Status"
                onChange={onStatusFilterChange}
              >
                <MenuItem value={statusCopyMap.all}>{statusCopyMap.all}</MenuItem>
                <MenuItem value={statusCopyMap.ready_to_sync}>
                  <SyncStatusLabel status={statusCopyMap.ready_to_sync} />
                </MenuItem>
                <MenuItem value={statusCopyMap.synced}>
                  <SyncStatusLabel status={statusCopyMap.synced} />
                </MenuItem>
                <MenuItem value={statusCopyMap.error}>
                  <SyncStatusLabel status={statusCopyMap.error} />
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Stack>
      )}

      {numSelected > 0 ? (
        <Stack direction="row" alignItems="center" justifyContent="end" spacing={2}>
          {onSyncSelected && (
            <Button variant="contained" onClick={onSyncSelected} startIcon={<Iconify icon="eva:sync-fill" />}>
              Sync
            </Button>
          )}
          {onDeleteSelected && (
            <Button
              variant="contained"
              color="error"
              startIcon={<Iconify icon="eva:trash-2-fill" />}
              onClick={onDeleteSelected}
            >
              Delete
            </Button>
          )}
        </Stack>
      ) : (
        // <Tooltip title="Reload">
        //   <IconButton onClick={onRefresh}>
        //     <Iconify icon="ci:refresh" />
        //   </IconButton>
        // </Tooltip>

        <Button variant="outlined" startIcon={<Iconify icon="ci:refresh" />} onClick={onRefresh}>
          Reload
        </Button>
      )}
    </RootStyle>
  );
}
